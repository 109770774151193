<template>
  <div v-if="chartData" class="space-y-3">
    <h2>{{ title }}</h2>
    <chart-container :beginAtZero="true" chart-type="bar" :chart-data="chartData" />
  </div>
</template>

<script>
  import ChartContainer from '@/components/Charts/ChartContainer';

  export default {
    components: {
      ChartContainer,
    },
    methods: {
      updateChartData() {
        this.chartData = {
          labels: this.usersNames,
          datasets: [
            {
              label: this.label,
              data: this.graphData,
            },
          ],
        };
      },
    },
    props: {
      title: { type: String, default: 'Unspecified title' },
      label: { type: String, default: 'Unspecified label' },
      usersNames: { type: Array, default: () => [] },
      graphData: { type: Array, default: () => [] },
    },
    mounted() {
      this.updateChartData();
    },
    data() {
      return {
        chartData: null,
      };
    },
    watch: {
      graphData() {
        this.updateChartData();
      },
    },
  };
</script>
