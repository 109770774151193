<template>
  <div v-if="userProfile.managedAccounts && managedAccountsAnalytics" class="space-y-5 container mx-auto my-8">
    <div v-if="userProfile.managedAccounts.length === 0">No clients were found.</div>
    <template v-else
      ><div>
        <h2>Clients</h2>
        <div
          @click="visitClientPage(client.profileInfo.screen_name)"
          v-for="client in userProfile.managedAccounts"
          :key="client.uid"
          class="card tweet cursor-pointer my-2 space-y-3 p-3"
        >
          <div class="flex justify-between">
            <div class="flex items-center space-x-3">
              <img class="rounded-full h-8 w-8" :src="client.profileInfo.photoURL" alt="Image" />
              <span>{{ client.profileInfo.screen_name }}</span>
            </div>
            <button
              class="btn icon transparent"
              @click.stop="showGhostwritingGoalsModal(client.uid)"
            >
              <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
                <g
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  ></path>
                  <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </g>
              </svg>
            </button>
          </div>

          <div v-if="!isNaN(getFollowersCountGoal(client.uid))" class="space-y-2">
            <span
              >Followers count goal : {{ managedAccountsAnalytics[client.uid].followersCount }} /
              {{ getFollowersCountGoal(client.uid) }}</span
            >
            <base-progress
              animated
              :height="4"
              progressClasses="mb-0"
              :percentage="
                calculatePercentage(
                  managedAccountsAnalytics[client.uid].followersCount.toString(),
                  getFollowersCountGoal(client.uid)
                ).toString()
              "
            ></base-progress>
          </div>
        </div>
      </div>

      <hr />

      <div class="flex space-x-2">
        <base-button
          v-for="tab in tabs"
          :key="tab"
          class="btn text"
          :class="{
            outline: selectedTab === tab,
            'outline outline-muted': selectedTab !== tab,
          }"
          @click="selectedTab = tab"
        >
          {{ tab }}
        </base-button>
      </div>
      <template v-if="graphsData">
        <ghostwriters-graph
          v-if="selectedTab === 'Impressions'"
          :title="'Clients Impressions'"
          :label="'Impressions'"
          :usersNames="graphsData.usersNames"
          :graphData="graphsData.impressions"
        ></ghostwriters-graph>
        <ghostwriters-graph
          v-if="selectedTab === 'Number of Tweets'"
          :title="'Clients Number Of Tweets'"
          :label="'Number Of Tweets'"
          :usersNames="graphsData.usersNames"
          :graphData="graphsData.numberOfTweets"
        ></ghostwriters-graph>
        <ghostwriters-graph
          v-if="selectedTab === 'Followers Count'"
          :title="'Clients Followers Count'"
          :label="'Followers Count'"
          :usersNames="graphsData.usersNames"
          :graphData="graphsData.followersCount"
        ></ghostwriters-graph>
      </template>
      <ghostwriting-goals-modal
        @close="closeGhostwritingGoalsModal"
        :show="isGhostwritingGoalsModalVisible"
        :selectedClientId="selectedClientId"
      ></ghostwriting-goals-modal
    ></template>
  </div>
</template>

<script>
  import GhostwritersGraph from '@/components/GhostwritersGraph';
  import BaseProgress from '@/components/BaseProgress';
  import { mapGetters, mapState } from 'vuex';
  import lodash from 'lodash';
  import { store } from '../store';
  import GhostwritingGoalsModal from '@/views/Modals/GhostwritingGoalsModal';

  export default {
    methods: {
      visitClientPage(clientId) {
        this.$router.push(`/ghostwriting/client/${clientId}`);
      },
      showGhostwritingGoalsModal(clientId) {
        this.selectedClientId = clientId;
        this.isGhostwritingGoalsModalVisible = true;
      },
      closeGhostwritingGoalsModal() {
        this.selectedClientId = null;
        this.isGhostwritingGoalsModalVisible = false;
      },
      getFollowersCountGoal(clientId) {
        return lodash.get(
          this.userProfile,
          `ghostwritingClientsData.${clientId}.goals.followersCount`,
          'not specified'
        );
      },
      calculatePercentage(value, max) {
        const percentage = (value * 100) / max;
        return percentage > 100 ? 100 : percentage;
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['writerGhostwritingThreads', 'managedAccountsAdditionalData']),
      managedAccountsAnalytics() {
        if (!this.writerGhostwritingThreads) {
          return null;
        } else {
          const managedAccountsAnalytics = {};

          this.userProfile.managedAccounts.forEach(
            (account) =>
              (managedAccountsAnalytics[account.uid] = {
                impressions: 0,
                followersCount: 0,
                numberOfTweets: 0,
                username: account.profileInfo.screen_name,
              })
          );

          const managedAccountsIds = Object.keys(managedAccountsAnalytics);

          managedAccountsIds.forEach((accountId) => {
            const userSpecificThreads = this.writerGhostwritingThreads.filter(
              (thread) => thread.user.id === accountId
            );

            const totalImpressions = userSpecificThreads.reduce(
              (previousValue, thread) => lodash.get(thread, 'impressionsCountOfTheFirstTweet', 0) + previousValue,
              0
            );
            const numberOfThreads = userSpecificThreads.length;
            const followersCount = lodash.get(
              this.managedAccountsAdditionalData,
              `${accountId}.followersCount`,
              0
            );

            managedAccountsAnalytics[accountId].impressions = totalImpressions;
            managedAccountsAnalytics[accountId].numberOfTweets = numberOfThreads;
            managedAccountsAnalytics[accountId].followersCount = followersCount;
          });

          return managedAccountsAnalytics;
        }
      },
      graphsData() {
        if (!this.managedAccountsAnalytics) {
          return null;
        } else {
          const managedAccountsAnalyticsToArray = Object.values(this.managedAccountsAnalytics);

          const usersNames = managedAccountsAnalyticsToArray.map((account) => account.username);
          const impressions = managedAccountsAnalyticsToArray.map((account) => account.impressions);
          const numberOfTweets = managedAccountsAnalyticsToArray.map(
            (account) => account.numberOfTweets
          );
          const followersCount = managedAccountsAnalyticsToArray.map(
            (account) => account.followersCount
          );

          return { usersNames, impressions, numberOfTweets, followersCount };
        }
      },
    },
    mounted() {
      if (!this.writerGhostwritingThreads) {
        store.dispatch('fetchWriterGhostwritingThreads');
      }
    },
    data() {
      return {
        tabs: ['Impressions', 'Number of Tweets', 'Followers Count'],
        selectedTab: 'Impressions',
        isGhostwritingGoalsModalVisible: false,
        selectedClientId: null,
      };
    },
    components: {
      GhostwritersGraph,
      BaseProgress,
      GhostwritingGoalsModal,
    },
  };
</script>
