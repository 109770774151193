<template>
  <modal :show="show" @close="$emit('close')" :class="[{ 'overlay--on': submitting }]">
    <div class="overlay" v-if="submitting">
      <div class="loader">
        <span class="loading loading-lg"
          ><inline-svg src="/img/icons/loading.svg"></inline-svg
        ></span>
      </div>
    </div>

    <div slot="header" class="modal-title">
      Client goals
    </div>

    <div>
      <div class="space-y-6">
        <base-input
          label="Followers goal"
          type="text"
          placeholder="Enter a number"
          v-model="followersCountInput"
        />
        <div class="flex items-center justify-end mt-3 space-x-2">
          <base-button
            :disabled="!isFormValid"
            type="primary"
            class="btn text primary w-full lg:w-auto"
            @click="updateGhostwritingGoals"
          >
            <span>Done</span>
          </base-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import { mapGetters } from 'vuex';
  import lodash from 'lodash';
  import SwalModalMixin from '../Mixins/SwalModalMixin.vue';
  import dao from '@/dao';

  export default {
    methods: {
      async updateGhostwritingGoals() {
        const goals = {
          followersCount: Number(this.followersCountInput),
        };

        this.submitting = true;

        try {
          await dao.userProfile.updateGhostwritingClientsData(
            this.userProfile.uid,
            this.selectedClientId,
            goals,
            'goals'
          );
          this.$notify({
            type: 'success',
            message: 'Client data updated successfully!',
          });
          this.$emit('close');
        } catch {
          this.swalModal({
            title: 'Error',
            html: `An error has occurred while trying to update the user goals.`,
            type: 'warning',
          });
        } finally {
          this.submitting = false;
        }
      },
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      isFormValid() {
        return !isNaN(this.followersCountInput) && this.followersCountInput > 0; 
      },
    },
    props: {
      show: Boolean,
      selectedClientId: { type: String, default: null },
    },
    data() {
      return {
        submitting: false,
        followersCountInput: 0,
      };
    },
    watch: {
      show(val) {
        if (val) {
          const clientFollowersCount = lodash.get(
            this.userProfile,
            `ghostwritingClientsData.${this.selectedClientId}.goals.followersCount`,
            0
          );

          this.followersCountInput = clientFollowersCount;
        }
      },
    },
    mixins: [SwalModalMixin],
  };
</script>
